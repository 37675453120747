<template>
  <div>
    <div class="flex flex-col sm:flex-row items-start mx-2">
      <div class="w-full sm:w-1/2 sm:mr-2 lg:mr-0">
        <div v-if="platform === 'bikeable'"
             class="relative h-full w-full">
          <img src="~assets/road-bikeable.svg"
               class="w-full h-full"
               :alt="$t('accessibility.road_image')" />
          <img
            class="absolute bottom-[8%] right-[10%] sm:-bottom-0.5 sm:right-0 lg:bottom-[6%] lg:right-[20%] rotate-6 sm:rotate-2 h-[260px] sm:h-[300px]"
            src="~assets/mascot-bikeable.png"
            :alt="$t('accessibility.mascot_neutral')">
          <!--       <a href="" target="_blank">-->
          <!--        <NuxtImg-->
          <!--            class="absolute left-2 sm:left-0 md:left-2 top-0 w-[70px] rounded-[35px] sm:w-[150px] sm:rounded-[75px] shadow-xl"-->
          <!--            :src="`sticker/bikeable-Sticker-${language?.toUpperCase()}.svg`"/>-->
          <!--        </a>-->
        </div>
        <div v-if="platform === 'walkable'"
             class="relative h-full w-full">
          <img src="~assets/road-walkable.svg"
               class="w-full h-full"
               :alt="$t('accessibility.road_image')" />
          <img class="absolute bottom-[12%] right-[5%] lg:right-[15%] rotate-6 h-[220px] sm:h-[250px] md:h-[300px]"
               src="~assets/mascot-walkable.png"
               alt="mascot">
          <!--        <a href="" target="_blank">-->
          <!--          <NuxtImg-->
          <!--              class="absolute left-2 sm:left-0 md:left-2 top-0 w-[70px] rounded-[35px] sm:w-[150px] sm:rounded-[75px] shadow-xl"-->
          <!--              :src="`sticker/walkable-Sticker-${language?.toUpperCase()}.svg`"/>-->
          <!--        </a>-->
        </div>
      </div>
      <div class="w-full sm:w-1/2 flex flex-col items-start gap-2 lg:translate-x-[10%]">
        <h1 class="font-black text-theme-primary mt-[1em]">{{ $t('general.slogan_' + platform) }}</h1>
        <p class="leading-relaxed">
          {{ $t('home.explanation_' + platform) }}
        </p>
        <LinkButtonPrimary to="/login" v-if="!auth.authenticated.value">
          {{ $t('home.cta_button_title') }}
        </LinkButtonPrimary>
      </div>
    </div>
    <div class="flex flex-col w-full items-start mt-4 px-1 mb-1">
      <h2>{{ $t('home.new_spots_title') }}</h2>
    </div>
    <div class="flex flex-col w-full mb-2 px-1">
      <div class="flex flex-col sm:flex-row mb-1 gap-1 sm:gap-2 items-start sm:items-center">
        <div>
          <RegionFilterBar />
        </div>
      </div>
      <ClientOnly>
        <SpotList :spotPreviews="spotPreviews"
                  :lastPage="lastPage"
                  :spotPreviewStatus="spotPreviewsStatus"
                  :error="spotPreviewsError"
                  :onLoadMore="loadMoreSpots" />
      </ClientOnly>
    </div>
    <div class="flex flex-col w-full items-start mt-4 px-1">
      <h2>{{ $t('news.page_title') }}</h2>
    </div>
    <div class="flex flex-col w-full mb-4 px-1">
      <NewsList :pageSize="6" />
    </div>
  </div>
</template>
<script setup lang="ts">

definePageMeta({
  layout: 'home'
});
const auth = useAuth();
const platformSettings = usePlatformSettings();
const platform = platformSettings.platformName;
const page = useState(() => 0);
const pageSize = ref(6);
const lastPage = useState(() => false);

const {
  data: spotPreviews,
  error: spotPreviewsError,
  status: spotPreviewsStatus,
  refresh: refreshSpotPreviews
} = await useAsyncData<SpotPreviewsResponse>(async () => {
  const result = await useApi().get<SpotPreviewsResponse>('spotPreviews', {
    size: (page.value + 1) * pageSize.value,
    regionId: platformSettings.region.value
  });

  lastPage.value = result.lastPage;
  return result.spotPreviews;
});

const loadMoreSpots = () => {
  page.value++;
  refreshSpotPreviews();
};

watch(platformSettings.region, () => {
  page.value = 0;
  spotPreviews.value = [];
  refreshSpotPreviews();
});

</script>
